<template>
  <div>
	<!-- 头部导航 -->
    <el-affix :z-index='2001'>
      <nav-bar></nav-bar>
    </el-affix>
	<!-- 工具栏 -->
	<tool-box></tool-box>

	<!-- 广告轮播加课程类型菜单 -->
	<div class='swiper-wrap'>
		<div class='swiper'>
			<swiper
				:slides-per-view="1"
				:space-between="0"
				navigation
				:autoplay="{delay:3000,disableOnInteraction:false}"
				loop
				:pagination="{clickable:true}">
				<swiper-slide
					@click='adInfo(item)' 
					v-for='(item,index) in bannerList' 
					:key='index'>
					<li :title="item.title"
						:style="{backgroundImage:'url(\'' + item.thumb + '\')',
						backgroundRepeat:'no-repeat',
						backgroundPosition:'center center',
						backgroundSize: 'cover',
						height:'574px',
						width:screenWidth + 'px',
						cursor:'pointer'}">
					</li>
				</swiper-slide>
			</swiper>
		</div>
		
		<div 
			class='menus-swiper'
			:style="{left:screenWidth>1200?((screenWidth -1200)/2)+'px':'0px'}">
		    <ul class='menus'>
		      <li
		        class='menus-list'
		        :key='index'
		        v-for="(item,index) in menusList">
		        <span class="title">{{item.title}}</span>
		        <div class='sub-title'>
		          <el-row :gutter="10">
		            <el-col
		              :span='8'
		              v-for="(subItem,sIndex) in item.children"
		              :key='sIndex'>
		              <div
						@click='toSpecialPage(subItem)'
		                class='sub-con'>{{subItem.name}}</div>
		            </el-col>
		          </el-row>
		        </div>
		      </li>
		    </ul>
		</div>
	</div>
	
	<!-- 课程内容 -->
	<div 
		class='recomment-wrap container'
		v-for='(item,index) in recommentList' 
		:key='index'>
		<!-- 标题 -->
		<div class='recomment-title'>
			<div class='title-list'>
				<div class='title first-spec'>{{item.title}}</div>
				<div class='sub-title'>
					<ul 
						class='sub-item' 
						v-for='(citem,cindex) in item.children' 
						:key='cindex'>
						<li 
							class='second-spec'
							:class="citem.checked?'secondSpecActive':''"
							@mouseenter="onCourseSpec(item,index,citem)">
							{{citem.title}}
						</li>
					</ul>
				</div>
			</div>
			<div class='more' @click='toCourseList(item)'>
				<span>更多</span>
				<svg-icon icon-class='youbian' class='more-icon'></svg-icon>
			</div>
		</div>
		<!-- 内容 -->
		<div class='recomment-content'>
			<div class='content-img'>
				<img 
					:src="currentImg[index].thumb" 
					title='' 
					alt=''>
			</div>
			<div v-if='currentComboSpec[index].content'>
				<div class ='content-course' 
					v-loading='currentComboSpec[index].loading'>
					<div class='course-list'>
						<div class='course-combo'>
							<el-row :gutter="15">
								<el-col 
									:span='8'
									v-show = 'bindex < 3'
									v-for='(bitem,bindex) in currentComboSpec[index].content.comboclass' 
									:key='bindex'
								>
									<comboclass-item :item='bitem'></comboclass-item>
								</el-col>
							</el-row>
						</div>
						<div class='course-paper'>
							<el-card class="box-card">
								<template #header>
									<div class="paper-header">
										<div class='header-left'>每日一练</div>
										<div class='more'>
											<span>更多</span>
											<svg-icon 
												icon-class='youbian' 
												class='more-icon'></svg-icon>
										</div>
									</div>
								</template>
								<div class='paper-list'>
									<ul>
										<li 
											v-show = 'pindex < 4'
											@click='toQuestion(pitem)'
											v-for='(pitem,pindex) in currentComboSpec[index].content.exam' 
											:key='pindex'>
											<div class='list-title'>
												<svg-icon 
													icon-class='dianji' 
													class='paper-icon'></svg-icon>
												<span>{{pitem.title}}</span>
											</div>
											<div class='list-btn'>
												<button>在线做题</button>
											</div>
										</li>
									</ul>
								</div>
							</el-card>
						</div>
					</div>
					<div class='course-news'>
						<div class='news-conent'>
							<div class="title">
								<el-row :gutter='20'>
									<el-col :span='12'>
										<div 
											class='title-item'
											@mouseenter="currentNewsActive=0"
											:class="currentNewsActive==0?'newsTitleActive':''">
											<svg-icon
												icon-class='zixun' 
												class='title-icon'></svg-icon>
											<span>报考指南</span>
										</div>
									</el-col>
									<el-col :span='12'>
										<div 
											class='title-item'
											@mouseenter="currentNewsActive=1"
											:class="currentNewsActive==1?'newsTitleActive':''">
											<svg-icon
												icon-class='zhunbeikaoshi' 
												class='title-icon'></svg-icon>
											<span>备考资料</span>
										</div>
									</el-col>
									<el-col :span='12'>
										<div 
											class='title-item' 
											@mouseenter="currentNewsActive=2"
											:class="currentNewsActive==2?'newsTitleActive':''">
											<svg-icon
												icon-class='baokaozhuanye' 
												class='title-icon'></svg-icon>
											<span>考试试题</span>
										</div>
									</el-col>
									<el-col :span='12'>
										<div 
											class='title-item'
											 @mouseenter="currentNewsActive=3"
											:class="currentNewsActive==3?'newsTitleActive':''">
											<svg-icon
												icon-class='kaoshi' 
												class='title-icon'></svg-icon>
											<span>资讯动态</span>
										</div>
									</el-col>
								</el-row>
							</div>
							<div class="list">
								<ul>
									<li 
										v-show = 'nindex < 10'
										@click='toNewsDetail(nitem)'
										v-for='(nitem,nindex) in currentComboSpec[index].content.art[currentNewsActive].article' 
										:key='nindex'>
										{{nitem.article_title}}
									</li>
								</ul>
							</div>
						</div>
						<div class='news-more' @click='toNewsList(item)'>
							<span>更多资讯</span>
							<svg-icon icon-class='youbian' class='more-icon'></svg-icon>
						</div>
					</div>
				</div>
			</div>
			<div v-else v-loading='true' class='content-course'></div>
		</div>
	</div>
	
	<!-- 题库 -->
	<div class='paper-wrap container'>
		<div class='paper-title'>
			<div class='title-list'>
				<div class='title'>题库</div>
			</div>
			<div class='more'  @click='toMoreExam()'>
				<span>更多</span>
				<svg-icon icon-class='youbian' class='more-icon'></svg-icon>
			</div>
		</div>
		<div class='paper-con'>
			<ul>
				<li>
					<img 
						src="../../assets/images/index/paper1.jpg" 
						alt="">
					<div class='paper-mask'>
						<div class='paper-item-top'>
							<div class='paper-item-icon'>
								<svg-icon
									icon-class='paper-moni' 
									class='title-icon paper-moni'></svg-icon>
							</div>
							<div class="paper-item-title">模拟试题</div>
							<div class="paper-item-text">
								模拟真实考试现场，让你备考更轻松，
								考试通关更有把握！
							</div>
						</div>
						<div class="paper-item-doc">
							<el-button 
								@click='toExamList(null, null, 7)'>点击进入</el-button>
						</div>
					</div>
				</li>
				<li >
					<img
						src="../../assets/images/index/paper2.jpg" 
						alt="">
					<div class='paper-mask'>
						<div class='paper-item-top'>
							<div class='paper-item-icon'>
								<svg-icon
									icon-class='paper-lianxi' 
									class='title-icon paper-lianxi'></svg-icon>
							</div>
							<div class="paper-item-title">每日一练</div>
							<div class="paper-item-text">
								依据新版无纸化考试标准，
								模拟考试流程，全面熟悉考试形式！
							</div>
						</div>
						<div class="paper-item-doc">
						   <el-button 
							@click='toExamList(null, null, 3)'>点击进入</el-button>
						</div>
					</div>
				</li>
				<li>
					<img
						src="../../assets/images/index/paper3.jpg" 
						alt="">
					<div class='paper-mask'>
						<div class='paper-item-top'>
							<div class='paper-item-icon'>
								<svg-icon
									icon-class='paper-miya' 
									class='title-icon paper-miya'></svg-icon>
							</div>
							<div class="paper-item-title">密押试卷</div>
							<div class="paper-item-text">
								行业从教多年教师，根据每年考试试卷，
								分析命题规律，提高押题命中率!
							</div>
						</div>
						<div class="paper-item-doc">
						   <el-button 
							@click='toExamList(null, null, 2)'>点击进入</el-button>
						</div>
					</div>
				</li>
				<li >
					<img
						src="../../assets/images/index/paper4.jpg" 
						alt="">
					<div class='paper-mask'>
						<div class='paper-item-top'>
							<div class='paper-item-icon'>
								<svg-icon
									icon-class='paper-chapter' 
									class='title-icon paper-chapter'></svg-icon>
							</div>
							<div class="paper-item-title">章节练习</div>
							<div class="paper-item-text">
								根据考试大纲，
								从海量试题中精选每个题型中的经典题，
								反复练习，轻松提升应考能力！
							</div>
						</div>
						<div class="paper-item-doc">
						   <el-button 
							@click='toExamList(null, null, 4)'>点击进入</el-button>
						</div>
					</div>
				</li>
				<li>
					<img
						src="../../assets/images/index/paper5.jpg" 
						alt="">
					<div class='paper-mask'>
						<div class='paper-item-top'>
							<div class='paper-item-icon'>
								<svg-icon
									icon-class='paper-true' 
									class='title-icon paper-true'></svg-icon>
							</div>
							<div class="paper-item-title">往年真题</div>
							<div class="paper-item-text">
								收录归纳近10年考试真题，
								从题型和出题方向了解考试，
								更好的掌握考试节奏！
							</div>
						</div>
						<div class="paper-item-doc">
						   <el-button 
							@click='toExamList(null, null, 5)'>点击进入</el-button>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<!-- footer -->
	<web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import { ElNotification } from 'element-plus';
import SwiperCore, { 
		Navigation, 
		Pagination, 
		Scrollbar, 
		A11y, 
		EffectFade, 
		Autoplay } from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);	

import NavBar from '@/components/page/NavBar'
import ToolBox from '@/components/toolbox'
import WebFooter from '@/components/page/WebFooter'

import comboclassItem from '@/views/course/components/ComboClassItem'

import {getSessionStorage,setSessionStorage,getLocalStorage} from '@/utils/auth'
import {mapState,mapMutations} from 'vuex';

import {
		getBanner,
		getMenus,
		getProSpec,
		getComboBySpec,
		getComboClass
	} from '@/api/index'
	
import Hls from 'hls.js';

export default {
  data() {
    return {
		bannerList:[],
		menusList:[],
		recommentList:[],
		currentYear:'',
		currentImg:[],//当前封面
		currentComboSpec:[],//当前班型
		currentNews:[],//当前资讯
		currentNewsActive:0,//当前选中新闻
    }
  },
  components: {
    NavBar,
    ToolBox,
	Swiper,
	SwiperSlide,
	WebFooter,
	comboclassItem,
  },
  computed:{
    ...mapState(['token']),
  },
  created(){
  	getBanner().then(res=>{//获取轮播图
  	  if(res.code==1){
  	    this.bannerList = res.data;
  	  }
  	});
	getMenus().then(res=>{//获取导航菜单
	  if(res.code==1){
	    this.menusList = res.data;
	  }
	});
	getProSpec().then(res=>{  //行业分类，考试专题
	  if(res.code==1){
			this.recommentList = res.data;
			res.data.map((item,index)=>{
				item.children.map((citem,cindex)=>{
					if(cindex==0){
						citem.checked = true;
						this.currentImg.push(citem);
						this.currentComboSpec.push(citem);
					}else{
						citem.checked = false;
					}
				})
			});
			this.currentComboSpec.map((item,index)=>{
				getComboBySpec({
					spec_id:item.id
				}).then(res=>{
					if(res.code==1){
						let comboclass = res.data.comboclass.filter((fitem,findex)=>{
							return fitem.status==1
						});
						res.data.comboclass = comboclass;
						item.content = res.data;
					}
				});
			});
	  }
	});
	this.registerWindowResize();
  },
  methods:{
	adInfo(list){//轮播图广告跳转
		if(list.type == 1){//海报
	  
		}
		if(list.type == 2){//课程列表
			let idArr = list.urlId.split(",");
			this.$router.push({
			  path:'/course/' + idArr[0] + '/'+idArr[1]
			});
		}
	},
	toCourseList(item){//导航跳转课程列表
		let curChildren = item.children;
		curChildren.map((item,index)=>{
			if(item.checked){
				this.$router.push({
					path:'/course/'+item.pid+'/'+item.id
				})
			}
		})
	},
	onCourseSpec(fitem,findex,citem){
		this.recommentList.map((item,index)=>{
			if(fitem.id==item.id){
				item.children.map((sitem,sindex)=>{
					if(sitem.id==citem.id){
						sitem.checked = true;
						//切换封面
						this.currentImg[findex] = sitem;
						//切换数据
						getComboBySpec({
							spec_id:sitem.id
						}).then(res=>{
							if(res.code==1){
								
								let comboclass = res.data.comboclass.filter((fitem,findex)=>{
									return fitem.status==1
								});
								res.data.comboclass = comboclass;
								
								this.currentComboSpec[index].content = res.data;
								this.currentComboSpec[index].loading = true;
								let _this = this;
								setTimeout(function(){
									_this.currentComboSpec[index].loading = false;
								},350)
								
							}
						});
					}else{
						sitem.checked = false;
					}
				})
			}
		})
	},
	toMoreExam(){
		this.$router.push('/exam')
	},
	toSpecialPage(item){
		this.$router.push('/special/' + item.id)
	},
	toQuestion(item){//跳转做题
		let token = getLocalStorage("token")
		if(token){
			setSessionStorage('currentPaper',item);
			this.$router.push({
				path:'/question'
			});
		}else{
			this.$notify.error({
			  title: '未登录',
			  message: '请先登录'
			});
			return;
		}
	},
	toNewsDetail(nitem){//跳转新闻详情
		this.$router.push({
			path:'/newsdetail/'+ nitem.article_id
		});
	},
	toNewsList(item){//跳转新闻列表
		let curChildren = item.children;
		curChildren.map((item,index)=>{
			if(item.checked){
				setSessionStorage('newsSpecialId',item.id)
				this.$router.push({
					path:'/news'
				});
			}
		})
	},
	toExamList(specId, childId, type) {//跳转题库
	  setSessionStorage('examListParams', {
	    specId: specId || this.recommentList[0].id,
	    specChildId: childId || this.recommentList[0].children[0].id,
	    type: type || 7,
	  })
	  this.$router.push('/examlist')
	},
	registerWindowResize(){
	  this.screenWidth = document.body.clientWidth;
	  this.screenHeight = document.body.clientHeight;
	  window.onresize = () => {
	    return (() => {
	      if(document.body.clientWidth > 1920){
	        this.screenWidth = 1920
	      }else if(document.body.clientWidth < 1200){
	        this.screenWidth = 1200
	      }else{
	        this.screenWidth = document.body.clientWidth;
	      }
	      this.screenHeight = document.body.clientHeight;
	    })();
	  };
	}
  }
}
</script>

<style scoped lang="scss">
	//轮播图
	.swiper-wrap{
	   width: 100%;
	   height: 574px;
	   overflow: hidden;
	   position: relative;
	   .menus-swiper{
	     position: absolute;
	     left:0px;
	     top:0px;
	     z-index: 99;
		 .menus{
		   width: 300px;
		   height: 574px;
		   overflow: hidden;
		   box-sizing: border-box;
		   padding:2px 10px;
		   background:rgba(255,255,255,0.7);
		   display: flex;
		   flex-direction: column;
		   .menus-list{
		     .title{
		       font-size:18px;
		       font-weight:bold;
					 line-height: 32px;
		     }
		     .sub-title{
		       font-size:14px;
					 line-height: 16px;
		       .sub-con{
				padding:5px 0px;
		        cursor: pointer;
		       }
		       .sub-con:hover{
		         color:$theme-color;
		       }
		     }
		   }
		 }
	   }
	}
	.el-carousel{
		--el-carousel-arrow-size:60px;
		--el-carousel-arrow-font-size:30px;
		--el-carousel-indicator-height:5px;
		--el-carousel-indicator-width:40px;
	}
	
	// 课程分类
	.recomment-wrap{
		margin-top:50px;
		.recomment-title{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			height:60px;
			line-height: 60px;
			box-sizing: border-box;
			overflow: hidden;
			border-bottom:1px solid #ccc;
			.title-list{
				display: flex;
				flex-direction: row;
				align-items: center;
				.title{
					width: 105px;
					font-size:24px;
					font-weight: bold;
					height: 100%;	
				}
				.sub-title{
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size:18px;
					height: 100%;
					margin-left:20px;
					ul{
						height: 100%;
						margin-bottom:2px;
						li{
							height:60px;
							line-height: 60px;
							margin:0px 12px;
							cursor: pointer;
							overflow: hidden;
							box-sizing: border-box;
						}
						li:hover{
							border-bottom: 2px solid $theme-color;
							color:$theme-color;
						}
						li.secondSpecActive{
							border-bottom: 2px solid $theme-color;
							color:$theme-color;
						}
					}
				}
			}
			.more{
				height: 100%;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				color:$info-color;
				.more-icon{
					margin-left:5px;
				}
			}
			.more:hover{
				color:$theme-color;
			}
		}
		.recomment-content{
			margin-top:15px;
			height: 502px;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			.content-img{
				width:303px;
				margin-right:15px;
				background-color: #fff;
				cursor: pointer;
			}
			.content-course{
				flex:1;
				height: 100%;
				display: flex;
				flex-direction: row;
				.course-list{
					width:630px;
					height: 100%;
					display: flex;
					flex-direction:column;
					margin-right:15px;
					.course-combo{
						width:100%;
					}
					.course-paper{
						flex:1;
						margin-top:20px;
						box-sizing: border-box;
						overflow: hidden;
						.box-card{
							display: flex;
							flex-direction: column;
							height: 100%;
							box-sizing: border-box;
							overflow: hidden;
							padding:0px 10px;
						}
						.paper-header{
							height:40px;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							.header-left{
								font-size: 16px;
								font-weight: bold;
							}
							.more{
								height: 100%;
								cursor: pointer;
								display: flex;
								flex-direction: row;
								align-items: center;
								color:$info-color;
								.more-icon{
									margin-left:5px;
								}
							}
							.more:hover{
								color:$theme-color;
							}
						}
						.paper-list{
							flex:1;
							box-sizing: border-box;
							overflow: hidden;
							ul{
								display: flex;
								flex-direction: column;
								li{
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: space-between;
									height:32px;
									cursor: pointer;
									.list-title{
										.paper-icon{
											margin-right:2px;
										}
									}
									.list-btn button{
										height:25px;
										outline: none;
										border:none;
										cursor: pointer;
									}
								}
								li:hover{
									color:$theme-color;
								}
								li:hover button{
									color:$theme-color;
								}
							}
						}
					}
				}
				.course-news{
					width:240px;
					height: 100%;
					box-sizing: border-box;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					.news-conent{
						width:100%;
						height:440px;
						box-sizing: border-box;
						background-color: #fff;
						padding:15px 10px;
						.title{
							.title-item{
								display: flex;
								flex-direction: row;
								justify-content: center;
								align-items: center;
								font-size:16px;
								margin-bottom: 15px;
								cursor: pointer;
								color:#333;
							}
							.title-item:hover{
								color:$theme-color;
							}
							.title-item.newsTitleActive{
								color:$theme-color;
							}
							border-bottom:1px solid $info-color;
						}
						.list{
							ul{
								width:100%;
								display: flex;
								flex-direction: column;
								li{
									cursor: pointer;
									height:35px;
									line-height: 35px;
									white-space:nowrap;
									overflow:hidden;
									text-overflow:ellipsis;
								}
								li:hover{
									color:$theme-color;
								}
							}
						}
					}
					.news-more{
						flex:1;
						margin-top:20px;
						padding:0px 20px;
						background-color: #fff;
						color:$info-color;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						cursor: pointer;
					}
					.news-more:hover{
						color:$theme-color;
					}
				}
			}
		}
	}
	
	// 题库
	.paper-wrap{
		margin-top:50px;
		margin-bottom:50px;
		.paper-title{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			height:60px;
			line-height: 60px;
			box-sizing: border-box;
			overflow: hidden;
			border-bottom:1px solid #ccc;
			.title-list{
				display: flex;
				flex-direction: row;
				align-items: center;
				.title{
					font-size:24px;
					font-weight: bold;
					height: 100%;	
				}
			}
			.more{
				height: 100%;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				color:$info-color;
				.more-icon{
					margin-left:5px;
				}
			}
			.more:hover{
				color:$theme-color;
			}
		}
		.paper-con{
			width:100%;
			margin-top:15px;
			height: 370px;
			display: flex;
			flex-direction: row;
			ul{
				display: flex;
				flex-direction: row;
				height: 100%;
				li{
					flex:1;
					margin-right:15px;
					position: relative;
					height: 100%;
					overflow: hidden;
					cursor: pointer;
					img{
						height: 100%;
					}
					.paper-mask{
						position: absolute;
						left:0px;
						top:0px;
						right:0px;
						bottom:0px;
						background:rgba(0,0,0,0.4);
						color:#fff;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.paper-item-top{
							display: flex;
							flex-direction: column;
							margin-top:10px;
							.paper-item-icon{
								width:80px;
								height: 80px;
								text-align: center;
								line-height: 80px;
								border-radius: 50%;
								margin:20px auto;
								background-color:rgba(255,255,255);
								font-size:45px;
								
								transition-property:all;
								transition-duration:1s;
								
								.paper-moni{
									color:$success-color;
								}
								.paper-lianxi{
									color:$danger-color
								}
								.paper-miya{
									color:$primary-color;
								}
								.paper-chapter{
									color:$warning-color;
								}
								.paper-true{
									color:#FF33CC;
								}
							}
							.paper-item-title{
								font-size:20px;
								text-align: center;
								font-weight:500;
							}
							.paper-item-text{
								padding:30px;
								text-align: center;
							}
						}
						.paper-item-doc{
							height:60px;	
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							color:#fff;
						}
					}
				}
				li:last-child{
					margin-right:0px;
				}
				li:hover .paper-item-icon{
					transform:rotateY(360deg);
					-ms-transform:rotateY(360deg);	/* IE 9 */
					-moz-transform:rotateY(360deg); 	/* Firefox */
					-webkit-transform:rotateY(360deg); /* Safari 和 Chrome */
					-o-transform:rotateY(360deg);
				}
			}
		}
	}
</style>